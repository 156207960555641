var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { memo } from "react";
import { useQuery } from "@tanstack/react-query";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCirclePlay } from "@fortawesome/free-solid-svg-icons";
/*#### modules ####*/
import MenuCategorias from "../../modules/Categories/MenuCategorias/MenuCategorias";
import MenuCategoriasHorizontales from "../../modules/Categories/MenuCategoriasHorizontales/MenuCategoriasHorizontales";
import SelectorDireccionesHeader from "../SelectorDireccionesHeader";
import BotonPencaHeader from "../../modules/BotonPencaHeader";
import Notificaciones from "../Notificaciones";
import CarritoHeader from "../../modules/carritoHeader";
import FavoritosHeader from "../../modules/favoritosHeader";
import MenuLoginHeader from "../../modules/menuLoginHeader";
import IconoRastreoHeader from "../../modules/IconoRastreoHeader";
import { Autocomplete } from "../../modules/AlgoliaSearch/Autocomplete";
import { useAppSelector } from "../../hooks/customReduxHooks";
import { ComponentesEnHeader, ImagenIsotipo, LoiPlayButton, MainHeader, TopSearch, } from "./HeaderStyled";
var BIENESTAR_Y_CUIDADO_CATEGORY_ID = 282;
var Header = function (_a) {
    var currentRefinement = _a.currentRefinement, refine = _a.refine, setSearchState = _a.setSearchState, _b = _a.redireccionBusqueda, redireccionBusqueda = _b === void 0 ? true : _b, _c = _a.autocompleteActivo, autocompleteActivo = _c === void 0 ? true : _c;
    var esCarrito = document.getElementById("esCarrito").value === "1";
    var empresaData = useAppSelector(function (state) { return state.empresa; }).empresaData;
    var usuario = useAppSelector(function (state) { return state.usuario; }).usuario;
    var configuraciones = useAppSelector(function (state) { var _a, _b; return (_b = (_a = state.empresa) === null || _a === void 0 ? void 0 : _a.empresaData) === null || _b === void 0 ? void 0 : _b.configuraciones; });
    var componentes = useAppSelector(function (state) { var _a, _b; return (_b = (_a = state.empresa) === null || _a === void 0 ? void 0 : _a.empresaData) === null || _b === void 0 ? void 0 : _b.componentes; });
    var pencaActiva = useAppSelector(function (state) { var _a, _b, _c; return (_c = (_b = (_a = state === null || state === void 0 ? void 0 : state.empresa) === null || _a === void 0 ? void 0 : _a.empresaData) === null || _b === void 0 ? void 0 : _b.configuraciones) === null || _c === void 0 ? void 0 : _c.pencaActiva; });
    var categoriasData = useQuery({
        queryKey: ["categoriesHomeHeader"],
        queryFn: getCategories,
    }).data;
    var url = empresaData && empresaData.sitio_web;
    return (React.createElement(MainHeader, { esCarrito: esCarrito },
        React.createElement(ComponentesEnHeader, null,
            React.createElement("li", null,
                React.createElement(ImagenIsotipo, { href: url }, empresaData && (React.createElement("img", { width: "100%", height: "100%", src: empresaData.isotipo, alt: empresaData.logo_titulo, title: empresaData.eslogan })))),
            pencaActiva && (React.createElement("li", null,
                React.createElement(BotonPencaHeader, null))),
            componentes && componentes.MENU_PRINCIPAL_HOVER && (React.createElement("li", { id: "opcion-header-menu" },
                React.createElement(MenuCategorias, { categorias: categoriasData, configuraciones: configuraciones }))),
            (componentes === null || componentes === void 0 ? void 0 : componentes.VIDEO_REELS) && (React.createElement("li", null,
                React.createElement(LoiPlayButton, { "aria-label": "LOi Play", href: "/loiplay" },
                    React.createElement(FontAwesomeIcon, { icon: faCirclePlay, size: "lg" }),
                    " LOi Play"))),
            React.createElement("li", { id: "opcion-header-buscador" },
                React.createElement(TopSearch, null,
                    React.createElement("div", { id: "buscador-react" },
                        React.createElement(Autocomplete, { autocompleteActivo: autocompleteActivo, configuraciones: configuraciones, currentRefinement: currentRefinement, refine: refine, setSearchState: setSearchState, redireccionBusqueda: redireccionBusqueda })))),
            !esCarrito &&
                componentes &&
                componentes.SELECTOR_DESTINO_ENVIO_EN_HEADER && (React.createElement("li", { id: "opcion-header-direcciones" },
                React.createElement(SelectorDireccionesHeader, null))),
            configuraciones &&
                configuraciones.rastreoEnvio !== 0 &&
                componentes &&
                componentes.ICONO_DE_RASTREO_EN_HEADER && (React.createElement("li", { id: "opcion-header-rastreo" },
                React.createElement(IconoRastreoHeader, null))),
            React.createElement("li", { id: "opcion-header-login" },
                React.createElement(MenuLoginHeader, { empresa: empresaData, usuario: usuario })),
            React.createElement("li", { id: "opcion-header-favoritos" },
                React.createElement(FavoritosHeader, null)),
            React.createElement("li", { id: "opcion-header-notificacion" },
                React.createElement(Notificaciones, null)),
            React.createElement("li", { id: "opcion-header-carrito" },
                React.createElement(CarritoHeader, null))),
        componentes &&
            componentes.MENU_PRINCIPAL_HORIZONTAL &&
            categoriasData && (React.createElement(MenuCategoriasHorizontales, { categorias: categoriasData, configuraciones: configuraciones }))));
};
export default memo(Header);
export var getCategories = function () { return __awaiter(void 0, void 0, void 0, function () {
    var data, newCategories;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0: return [4 /*yield*/, fetch("/index.php?ctrl=index&act=categorias")];
            case 1:
                data = _a.sent();
                if (!data.ok) {
                    throw new Error(data.statusText);
                }
                return [4 /*yield*/, data.json()];
            case 2:
                newCategories = _a.sent();
                newCategories = newCategories.map(function (category) {
                    if (category.id === BIENESTAR_Y_CUIDADO_CATEGORY_ID) {
                        var newCategory = __assign({}, category);
                        newCategory.hijos.push({
                            id: 0,
                            nombre: "MiBelleza",
                            descripcion: "",
                            urlseo: "https://mibelleza.uy",
                            type: "custom",
                            imagen: "https://s3-sa-east-1.amazonaws.com/mibelleza/_img_empresas/mibelleza.png",
                        });
                        return newCategory;
                    }
                    return category;
                });
                return [2 /*return*/, newCategories];
        }
    });
}); };
