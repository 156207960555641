var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from "styled-components";
export var ContenedorCategoria = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  margin-top: 15px;\n  width: 100%;\n"], ["\n  display: flex;\n  justify-content: center;\n  margin-top: 15px;\n  width: 100%;\n"])));
export var CategoriaInfo = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 1;\n"], ["\n  align-items: center;\n  display: flex;\n  flex-direction: column;\n  justify-content: center;\n  flex: 1;\n"])));
export var ContainerIcon = styled.a(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  background-color: #fff;\n  color: ", ";\n  cursor: pointer;\n  transition: 1s;\n  align-items: center;\n  border-radius: 100%;\n  display: flex;\n  width: 100%;\n  aspect-ratio: 1/1;\n  max-width: 100px;\n  justify-content: center;\n  position: relative;\n  border: ", " ;\n  @media screen and (max-width: 479px) {\n    max-width: 80px;\n  }\n\n  &:hover {\n    background-color: ", " ;\n    color: #fff;\n  }\n"], ["\n  background-color: #fff;\n  color: ", ";\n  cursor: pointer;\n  transition: 1s;\n  align-items: center;\n  border-radius: 100%;\n  display: flex;\n  width: 100%;\n  aspect-ratio: 1/1;\n  max-width: 100px;\n  justify-content: center;\n  position: relative;\n  border: ", " ;\n  @media screen and (max-width: 479px) {\n    max-width: 80px;\n  }\n\n  &:hover {\n    background-color: ", " ;\n    color: #fff;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.primarioHover;
}, function (props) { return props.theme.generalBorder; }, function (_a) {
    var theme = _a.theme;
    return theme.primarioHover;
});
export var CategoriaFoto = styled.a(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  align-items: center;\n  background-image: url(\"", "\");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  border-radius: 100%;\n  display: flex;\n  width: 100%;\n  aspect-ratio: 1/1;\n  max-width: 100px;\n  justify-content: center;\n  position: relative;\n  border: ", " ;\n  @media screen and (max-width: 479px) {\n    max-width: 80px;\n  }\n\n  &:hover {\n    border: thin solid ", ";\n  }\n"], ["\n  align-items: center;\n  background-image: url(\"", "\");\n  background-size: contain;\n  background-repeat: no-repeat;\n  background-position: center;\n  border-radius: 100%;\n  display: flex;\n  width: 100%;\n  aspect-ratio: 1/1;\n  max-width: 100px;\n  justify-content: center;\n  position: relative;\n  border: ", " ;\n  @media screen and (max-width: 479px) {\n    max-width: 80px;\n  }\n\n  &:hover {\n    border: thin solid ", ";\n  }\n"])), function (props) { return props.backgroundImage; }, function (props) { return props.theme.generalBorder; }, function (_a) {
    var theme = _a.theme;
    return theme.primarioHover;
});
export var CategoriaTitulo = styled.a(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  color: ", ";\n  text-align: center;\n  text-decoration: none;\n  font-size: .7rem;\n  margin: 3px 0;\n  padding: 5px;\n\n  &:hover {\n    color: ", ";\n  }\n"], ["\n  color: ", ";\n  text-align: center;\n  text-decoration: none;\n  font-size: .7rem;\n  margin: 3px 0;\n  padding: 5px;\n\n  &:hover {\n    color: ", ";\n  }\n"])), function (props) { return props.theme.negro; }, function (_a) {
    var theme = _a.theme;
    return theme.primarioHover;
});
export var CategoriaPrecioMin = styled.span(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  background: ", ";\n  border-radius: ", ";\n  bottom: 0;\n  color: ", ";\n  display: flex;\n  align-self: center;\n  font-size: 9px;\n  font-weight: bold;\n  justify-content: center;\n  padding: 5px 8px;\n  text-align: center;\n"], ["\n  background: ", ";\n  border-radius: ", ";\n  bottom: 0;\n  color: ", ";\n  display: flex;\n  align-self: center;\n  font-size: 9px;\n  font-weight: bold;\n  justify-content: center;\n  padding: 5px 8px;\n  text-align: center;\n"])), function (props) { return props.theme.primarioClaro; }, function (props) { return props.theme.totalBorderRadius; }, function (props) { return props.theme.primarioHover; });
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6;
