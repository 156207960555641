var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g = Object.create((typeof Iterator === "function" ? Iterator : Object).prototype);
    return g.next = verb(0), g["throw"] = verb(1), g["return"] = verb(2), typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Usuario from "../services/usuario";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
export var usuarioToken = localStorage.getItem("usr_token");
export var usuarioId = localStorage.getItem("usr_id");
export var apiToken = "Bearer " + process.env.API_TOKEN;
var initialState = {
    usuario: null,
    historial: null,
};
var usuarioSlice = createSlice({
    name: "usuario",
    initialState: initialState,
    reducers: {
        resetUsuario: function (state) { return initialState; },
    },
    extraReducers: function (builder) {
        builder.addCase(getUsuario.fulfilled, function (state, action) {
            state.usuario = action.payload;
        });
        builder.addCase(getHistorialProductos.fulfilled, function (state, action) {
            state.historial = action.payload.listaProductos;
        });
        builder.addCase(borrarHistorialProductos.fulfilled, function (state, action) { });
    },
});
//get datos de usuario logueado
export var getUsuario = createAsyncThunk("getUsuario", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_1, error_usuario;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Usuario.getUsuario()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_1 = _a.sent();
                error_usuario = "ERROR_GET_USUARIO";
                console.error(error_usuario, err_1);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var getHistorialProductos = createAsyncThunk("getHistorialProductos", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_2, error_usuario;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Usuario.getHistorialProductos()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_2 = _a.sent();
                error_usuario = "ERROR_GET_HISTORIAL_USUARIO";
                console.error(error_usuario, err_2);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var borrarHistorialProductos = createAsyncThunk("borrarHistorialProductos", function () { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_3, error_usuario;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Usuario.borrarTodoHistorialProductos()];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_3 = _a.sent();
                error_usuario = "ERROR_BORRAR_HISTORIAL_USUARIO";
                console.error(error_usuario, err_3);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export var borrarVisualizacionHistorial = createAsyncThunk("borrarHistorialProductos", function (id) { return __awaiter(void 0, void 0, void 0, function () {
    var response, err_4, error_usuario;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                _a.trys.push([0, 2, , 3]);
                return [4 /*yield*/, Usuario.borrarVisualizacionDeHistorial(id)];
            case 1:
                response = _a.sent();
                return [2 /*return*/, response];
            case 2:
                err_4 = _a.sent();
                error_usuario = "ERROR_BORRAR_HISTORIAL_USUARIO";
                console.error(error_usuario, err_4);
                return [3 /*break*/, 3];
            case 3: return [2 /*return*/];
        }
    });
}); });
export default usuarioSlice;
