import React from "react";
import { ContenedorCategoria, CategoriaFoto, CategoriaInfo, CategoriaTitulo, CategoriaPrecioMin, ContainerIcon, } from "./CategoriaStyled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
var SIMBOLO_PESOS = "$";
var MAX_PESOS = 300;
var SIMBOLO_DOLAR = "USD";
var MAX_DOLAR = 8;
var Categoria = function (_a) {
    var categoria = _a.categoria;
    var precioCorrecto = (categoria.simboloMonedaMenorPrecio === SIMBOLO_PESOS &&
        categoria.menor_precio <= MAX_PESOS) ||
        (categoria.simboloMonedaMenorPrecio === SIMBOLO_DOLAR &&
            categoria.menor_precio <= MAX_DOLAR)
        ? categoria.menor_precio
            ? "Desde ".concat(categoria.simboloMonedaMenorPrecio, " ").concat(categoria.menor_precio)
            : null
        : categoria.mayor_porcentaje
            ? "Hasta ".concat(categoria.mayor_porcentaje, "% OFF")
            : null;
    return (React.createElement(ContenedorCategoria, null,
        React.createElement(CategoriaInfo, { key: categoria.id, className: "images" },
            categoria.icon ? (React.createElement(ContainerIcon, { href: categoria.urlseo },
                React.createElement(FontAwesomeIcon, { icon: categoria.icon, size: "3x" }))) : (React.createElement(CategoriaFoto, { href: categoria.urlseo, backgroundImage: categoria.imagen, className: "container-image", target: categoria.type === "custom" ? "_blank" : "_self" })),
            React.createElement(CategoriaTitulo, { href: categoria.urlseo, target: categoria.type === "custom" ? "_blank" : "_self" }, categoria.nombre),
            precioCorrecto && categoria.type !== "custom" && (React.createElement(CategoriaPrecioMin, null, precioCorrecto)))));
};
export default Categoria;
