const primario = "black";
const secundario = "#e3e3e3";
const secundarioClaro = "#eee";
const goMuyClaro = "#f9ffe5";
const go = "#00aa33";

const primarioHover = "#303030";
const generalShadhow = "none";
const totalBorderRadius = "0";
const generalBorderRadius = "0";
const mediumBorderRadius = "0";
const smallBorderRadius = "0";
const generalBorder = "0";

export const mibeTheme = {
  fondo: "#eaeded",
  overlay: "#eaeded",
  negro: "#002e36",
  blanco:'#ffffff',
  primario, // negro
  primarioHover, //gris oscuro
  primarioMuyClaro: "#eee",
  secundario: secundario, //gris medio
  secundarioClaro: secundarioClaro,
  terciario: "#858585", //gris claro
  peligroOscuro: "#3f0000",
  peligroClaro: "#fff5f5",
  estandarClaro: "#dce8ff",
  estandar: "#0c3071",
  estandarOscuro: "#02102a",
  inactivoClaro: "#f2f2f2",
  inactivo: "#d3d3d3",
  inactivoOscuro: "#424242",
  goMuyClaro: goMuyClaro,
  goClaro: "#d4ffe4",
  go: go,
  goOscuro: "#018227",
  waitClaro: "#fffcdd",
  wait: "#F5DF4D",
  waitOscuro: "#4a450c",
  stopClaro: "#f4d4d4", //rojo claro
  stop: "#5B0082", //rojo
  stopOscuro: "#590000", //rojo oscruo
  cancelClaro: "#c2c2c2", //gris medio
  cancel: "#555555", //gris muy oscuro
  cancelOscuro: "#171717", //gris casi negro
  alert: "#ff8000",
  bordeBotonStandar: "3px",
  tendenciasBorderRadius: "0",
  tendenciasBorder: generalBorder,
  //generales
  fondoPopUp: "rgba(0, 0, 0, 0.3)",
  smallMobileBreakPoint: "919px",
  generalMobileBreakPoint: "919px",
  mediumBreakPoint: "919px",
  bigBreakPoint: "1140px",
  maxBreakPoint: "1440px",
  body: "white",
  bodyFonts: `"helvetica neue",helvetica,arial,sans-serif`,
  bodyFontsweight: "500",
  contenedorHomeTopMargin: "0",
  contenedorHomeTopMarginMobile: "0",
  generalShadhow: generalShadhow,
  generalBorderRadius: generalBorderRadius,
  mediumBorderRadius: mediumBorderRadius,
  smallBorderRadius: smallBorderRadius,
  verySmallBorderRadius: 0,
  generalBorder: generalBorder,
  totalBorderRadius: totalBorderRadius,
  buttonBorderRadius: 0,
  /*titulos de las secciones*/
  titulosDeSeccionesFont: `'Montserrat', sans-serif`,
  titulosDeSeccionesFontWheigth: "600",
  titulosDeSeccionesFontSize: "26px",
  titulosDeSeccionesFontSizeMobile: "24px",
  titulosDeSeccionesPadding: "0",
  titulosDeSeccionesMargin: "15px 0",
  titulosDeSeccionesMarginMobile: "15px 0",
  titulosDeSeccionesHeroPadding: "0",
  titulosDeSeccionesHeroMargin: "20px 0",
  titulosDeSeccionesHeroMarginMobile: "20px 10px",
  titulosDeSeccionesJustifyContent: "space-between",
  titulosDeSeccionesTextTransform: "uppercase",
  titulosDeSeccionesBorderBottom: "thin solid black",
  /*margin de las secciones generales*/
  marginDeSeccionesGanerales: "30px 0",
  marginDeSeccionesGaneralesDesktop: "30px 15px 15px 15px",
  marginDeSeccionesGaneralesMobile: "10px",

  /*boton add to cart*/
  addToCartTexto: "white",
  addToCartFondo: "black",
  addToCartBorder: "black",
  addToCartTextoHover: "black",
  addToCartFondoHover: "white",
  addToCartBorderHover: "black",

  /*boton outline*/
  botonOutlineTexto: "black",
  botonOutlineFondo: "white",
  botonOutlineBorder: "black",
  botonOutlineTextoHover: "white",
  botonOutlineFondoHover: "black",
  botonOutlineBorderHover: "black",

  //slidershome
  sliderNovedadesWidth: "100% ",
  //productcard
  productCardTextAling: "left",
  productCardHoverEffect: "none",
  //header
  headerOptionsMargin: "0 10px",
  headerOptionsMarginTablet: "0",
  displayMensajeDeBienvenidaEnMobile: "block",
  headerLogoWidth: "180px",
  headerLogoHeigth: "35px",
  headerLogoWidthMobile: "90px",
  headerLogoHeigthMobile: "19px",
  headerLogoWidthTablet: "120px",
  headerLogoHeigthTablet: "25px",
  headerLogoImgageWidthMobile: "90px",
  headerLogoMargin: "-7px 0 0 0",
  headerLogoMarginMobile: "-7px 0 0 0",
  headerPadding: "30px 10px",
  headerPaddingTablet: "15px 10px",
  headerPaddingMobile: "15px",
  headerHeight: "auto",
  headerMobileHeight: "100px",
  headerMobileHeightCarrito: "70px",
  fondoHeader: "white",
  fondoHeaderHover: secundario,
  headerSearchWidth: "100%",
  headerSearchWidthMobile: "100%",
  headerSearchBackground: "#f3f3f3",
  headerSearchBorder: "thin solid silver",
  headerSearchMargin: "0",
  headerSearchMarginMobile: "0",
  headerSearchShadow: "none",
  indicadorEnviandoA: "block",
  hearderCartIconmargin: "0 0 0 10px",
  loginButtonMargin: "0 10px",
  favoritosButtonMargin: "0 10px",
  //menuMobile
  backgroundMenuMobile: primarioHover,
  hoverbackgroundMenuMobile: primario,
  buttonsColorMenuMobile: secundario,
  mainButtonsBackgroundColorMenuMobile: primario,
  //footermible
  avatarMobileBorderRadius: "0",
  footerDisplay: "flex",
  footerMobileVisibilityBreakPoint: "919px",
  footerBgColor: "#4c4c4c",
  footerInformationJustifyContent: "left",
  footerInformationTextAlign: "left",
  footerColorTextos: "white",
  footerColumnWidth: "100%",
  footerColumnMargin: 0,
  footerOrderRedesInfoEmpresa: 1,
  footerOrderRedesSociales: 2,
  footerFlexDirection: "row",
  //selector de direcciones
  bordeSelectorDestinoDeEnvio: "0",
  paddingSelectorDestinoDeEnvio: "0",
  selectorDeDestinoPosicionMobile: "absolute",
  selectorDeDestinoTop: "60px",
  selectorDeDestinoMobileBorderRadius: "0",
  selectorDeDestinoMobileBoxShadow: "none",
  selectorDeDestinoMobileDisplayMode: "flex",
  selectorDeDestinoMobileBackground: secundarioClaro,
  //sliders
  fondoFlechasSliders: "rgba(255,255,255,0.4)",
  fondoFlechasSlidersHover: primarioHover,
  colorIconoFlechaSlider: primarioHover,
  colorIconoFlechaSliderHover: "rgba(255,255,255,1)",
  borderRadiusFlechaSliderDerecha: "0",
  borderRadiusFlechaSliderIzquierda: "0",
  shadowFlechaSlider: "0",
  shadowHoverFlechaSlider: "0",
  borderFlechaSlider: "thin solid black",
  borderHoverFlechaSlider: "thin solid black",
  BotonFlechaSliderNextRight: "0",
  BotonFlechaSliderNextLeft: "0",
  BotonFlechaSliderWidth: "38px",
  BotonFlechaSliderHeight: "38px",
  BotonFlechaSliderHover: primarioHover,
  flechasConTodosLosBordes: true,
  //banners
  bannersHomeMargin: "0",
  bannersHomeMarginDesktop: "0",
  bannersHomeMarginMobile: "0",
  bannersHomeBorderRadius: "0px",
  bannersHomeBorderRadiusMobile: "0px",
  bannerHomeSMAspectRatio: "1 / 1",
  bannerHomeMDAspectRatio: "720 / 300",
  bannerHomeLGAspectRatio: "1440 / 480",
  //novedades por categorias
  novedadesPorCategoriaSliderMargin: "0 0 40px 0",
  novedadesPorCategoriaSliderMarginDesktop: "0 0 40px 0",
  novedadesPorCategoriaSliderMarginMobile: "0 0 10px 0",
  novedadesPorCategoriaSliderBorder: 0,
  novedadesPorCategoriaSliderWidth: "100%",
  novedadesPorCategoriaSliderWidthMobile: "100%",
  novedadesPorCategoriaFlexDirection: "row",
  novedadesPorCategoriaParesFlexDirection: "row-reverse",
  //colecciones
  listaColeccionesHeaderBorderRadius: "0",
  coleccionTitleFontFamily: "",
  coleccionTitleTextTransform: "uppercase",
  coleccionTitleFontSize: "26px",
  coleccionFlexWrap: "nowrap",
  coleccionHeaderheight: "160px",
  coleccionWidth: "31.33% ",
  coleccionWidthTablet: "70%",
  coleccionWidthMobile: "70%",
  coleccionBoxBoxBorderRadius: "0",
  coleccionBorder: 0,
  coleccionHeaderFontSize: "",
  coleccionHeaderFontFamily: "",
  coleccionListaArticulosMargin: "0",
  coleccionHeaderBorderRadius: "0",
  coleccionBackGround: "#f7f7f7",
  //tarjeta de producto y listados
  tarjetaDeProductoImagenPadding: "0",
  tarjetaWidth: "18%",
  tarjetaPadding: "0.5%",
  tarjetaMargin: "0.5%",
  tarjetaWidth1140: "23%",
  tarjetaWidth719: "31%",
  tarjetaWidth479: "48%",
  infoDeTipoDeEnvioTextAlign: "left",
  //textoseo
  textoSeoFontSize: "16px",
  textoSeoPadding: "15px",
  textoSeoAlign: "left",
  //lista de tienda oficiales
  tiendasHeaderBorderRadius: "0",
  tiendasHeaderTextAling: "left",
  tiendasHeaderAlingItems: "flex-start",
  tiendasHeaderJustifyContent: "center",
  tiendasHeaderBgColor: "white",
  tiendasHeaderColor: "black",
  tiendasHeaderPadding: "0",
  //cuadro de tienda oficial
  tiendasCuadroLogoDisplay: "none",
  tiendaHeaderBorderRadius: " 0",
  //boton de iniciio de sesion
  fondoBotonLogin: "white",
  borderColorBotonLogin: "white",
  colorTextoBotonLogin: primario,
  hotSaleColor: "#5B0082",
  hotSaleColorOscuro: "#3D0064",
  hotSaleClaro: "#F0E0FF",
  separacion: "15px",
  separacionLanding: "30px",
  borderDefaultSlider: "thin solid " + primarioHover,

  breakPointVerySmall: "479px",
  breakPointSmall: "719px",
  breakPointMedium: "919px",
  breakPointBig: "1140px",
  breakPointVeryBig: "1440px",

  descuentosPorMedioDePagoColor: "#6741d9",
  descuentosPorMedioDePagoColorClaro: "#b197fc",
  descuentosPorMedioDePagoColorMuyClaro: "#e8e0ff",
};
